
.portal-category
  margin-bottom: calc(8 * var(--layout-spacing-unit));

  &--empty
    margin-bottom: 0

  &--dragging
    .portal-tile__box,
    .tile-add__button
      transform: rotate(-10deg)
    .portal-tile--minified .portal-tile__box
      transform: none

  &__tiles
    display: grid
    grid-template-columns: repeat(auto-fill, var(--app-tile-side-length))
    grid-gap: calc(6 * var(--layout-spacing-unit))

    &--editmode
      display: block

  &__edit-button
    padding 0

  &__title
    height: var(--button-size)
    display: inline-flex
    align-items: center
    margin-top: 0
    margin-bottom: calc(3 * var(--layout-spacing-unit))

    & [draggable="true"]
      cursor: move
  &__title-virtual
    font-style: italic
