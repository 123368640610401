.form-element {
  margin-top: calc(3 * var(--layout-spacing-unit));
/*
  &--invalid
    > .form-element__wrapper
      padding-left: var(--layout-spacing-unit)
      margin-left: 2px
      box-shadow: inset 2px 0 var(--font-color-error)
   */
}
.form-element input,
.form-element select,
.form-element label {
  margin: 0;
}
.form-element .input-error-message {
  margin: 0;
  margin-top: var(--layout-spacing-unit);
}
.form-element--CheckBox {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "checkbox label" "invalidMessage invalidMessage";
}
.form-element--CheckBox input {
  grid-area: checkbox;
}
.form-element--CheckBox label {
  grid-area: label;
}
.form-element--CheckBox .input-error-message {
  grid-area: invalidMessage;
}
/*# sourceMappingURL=src/components/forms/FormElement.css.map */