.image-upload__canvas {
  height: 10rem;
  width: 10rem;
  cursor: pointer;
  display: flex;
  background: var(--bgc-checkerboard);
}
.image-upload__canvas img {
  max-height: 10rem;
  margin: auto;
  max-width: 100%;
}
.image-upload__footer {
  margin: var(--layout-spacing-unit) 0;
  display: flex;
}
.image-upload__footer button + button {
  margin-left: var(--layout-spacing-unit);
}
.image-upload__file-input {
  visibility: hidden;
  position: absolute;
}
.image-upload__nofile {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: var(--bgc-inputfield-on-container);
}
.image-upload__nofile span {
  margin: auto;
}
/*# sourceMappingURL=src/components/widgets/ImageUploader.css.map */