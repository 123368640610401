
.modal-wrapper--loading.modal-wrapper--isVisible
  z-index: $zindex-10

.loading-overlay
  width: 2rem
  height: 2rem
  display: flex
  color: rgba(255, 255, 255, 0.4)

  svg
    animation: 2s linear infinite svg-animation
    width: 100%
    height: 100%

  &__circle
    animation: 1.4s ease-in-out infinite both circle-animation
    display: block
    fill: transparent
    stroke: currentColor
    stroke-linecap: round
    stroke-dasharray: 283
    stroke-dashoffset: 280
    stroke-width: 10px
    transform-origin: 50% 50%

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
