.portal-category {
  margin-bottom: calc(8 * var(--layout-spacing-unit));
}
.portal-category--empty {
  margin-bottom: 0;
}
.portal-category--dragging .portal-tile__box,
.portal-category--dragging .tile-add__button {
  transform: rotate(-10deg);
}
.portal-category--dragging .portal-tile--minified .portal-tile__box {
  transform: none;
}
.portal-category__tiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--app-tile-side-length));
  grid-gap: calc(6 * var(--layout-spacing-unit));
}
.portal-category__tiles--editmode {
  display: block;
}
.portal-category__edit-button {
  padding: 0;
}
.portal-category__title {
  height: var(--button-size);
  display: inline-flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: calc(3 * var(--layout-spacing-unit));
}
.portal-category__title [draggable="true"] {
  cursor: move;
}
.portal-category__title-virtual {
  font-style: italic;
}
/*# sourceMappingURL=src/components/PortalCategory.css.map */