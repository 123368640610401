
.image-upload
  &__canvas
    height: 10rem
    width: 10rem
    cursor: pointer
    display: flex
    background: var(--bgc-checkerboard)
    img
      max-height: 10rem
      margin: auto
      max-width: 100%
  &__footer
    margin: var(--layout-spacing-unit) 0
    display: flex
    button + button
      margin-left: var(--layout-spacing-unit)
  &__file-input
    visibility: hidden
    position: absolute
  &__nofile
    height: 100%
    width: 100%
    display: flex
    background-color: var(--bgc-inputfield-on-container)
    span
      margin: auto
