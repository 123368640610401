
.form-element
  margin-top: calc(3 * var(--layout-spacing-unit))

  input,
  select,
  label
    margin: 0

  .input-error-message
    margin: 0
    margin-top: var(--layout-spacing-unit)

  &--CheckBox
    display: grid
    grid-template-columns: auto 1fr
    grid-template-rows: auto auto
    grid-template-areas: "checkbox label" "invalidMessage invalidMessage"

    input
      grid-area: checkbox
    label
      grid-area: label
    .input-error-message
      grid-area: invalidMessage
  /*
  &--invalid
    > .form-element__wrapper
      padding-left: var(--layout-spacing-unit)
      margin-left: 2px
      box-shadow: inset 2px 0 var(--font-color-error)
   */
